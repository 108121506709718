import {mapMutations, mapGetters, mapActions} from 'vuex';
import novaPoshta from './components/nova-poshta/index.vue'
import google from '@/components/widgets/google/index.vue'
import facebook from '@/components/widgets/facebook/index.vue'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import fancyBox from 'vue-fancybox';
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {ModelListSelect} from "vue-search-select";

export default {
  name: "partners",
  components: {
    novaPoshta,
    google,
    facebook,
    CoolLightBox,
    fancyBox,
    ModelListSelect
  },
  data() {
    return {
      selectedFile: false,
      currentCity: '',
      currentState: '',
      currentCountry: '',
      searchStateTitle: "",
      validationErrors: {},
      dfdf: [],
      cityCollection: [],
      statesCollection: [],
      searchCountryTitle: '',
      index: null,
      allImg: [],
      preview: [],
      file: null,
      files: [],
      socialConnect: false,
      socialInfo: {
        token: localStorage.getItem('user_token'),
        provider: 'facebook'
      },
      editProfiCertificate: false,
      editCourier: false,
      editNovaPoshta: false,
      editUkrPoshta: false,
      profileDisabled: true,
      contactDisabled: true,
      messageDisabled: true,
      addressDisabled: true,
      openNewAddress: true,
      professional: localStorage.getItem('professional'),
      deliveryAddressCheck: [{}],
      editPersonalData: true,
      payload: {
        name: '',
        last_name: '',
        message: '',
        middle_name: '',
        phone: '',
        telegram: '',
        viber: '',
        email: '',
        state: '',
        city: '',
        country: '',
        work: '',
        files: [],
        address: {
          city: '',
          state: '',
          country: '',
          street: '',
          house: '',
          flat: ''
        },
        street: '',
        room: '',
        homeNum: ''
      },
    };

  },
  validations: {
    payload: {
      country: {
        required
      },
      state: {
        required
      },
      city: {
        required
      }
    },
  },
  watch: {
    'payload.country'() {
      this.fetchState(this.payload.country)
    },
    'payload.state'() {
      let {country, state} = this.payload;
      this.fetchTowns({country, state})
    },
    'payload.city'() {
      // console.log(this.payload, 'payload')
    }

  },
  created() {

    this.fetchCountries().then(() => {
      console.log(this.user, 'user');
      this.countries.find((el) => {
        if (el.code === this.user.country) {
          this.currentCountry = el.name
        }
      })

    }).then(() => {
      if (this.user.state !== '') {
        this.fetchState(this.user.country).then(() => {

          this.states.find((el) => {
            if (el.code === this.user.state) {
              this.currentState = el.name
            }
          })

        })
      }

    }).then(() => {
      if (this.user.city !== '') {
        this.fetchTowns(this.user).then(() => {

          this.citiesList.find((el) => {
            if (el.code === this.user.city) {
              this.currentCity = el.name
            }
          })
        })
      }
    })


    this.allImg = this.user.files

    if (this.isAuthenticated) {
      this.fetchUser().then(() => {
        this.setData()
      })
    } else {
      this.router.push('home')
    }

  },
  mounted() {
    if (this.deliveryAddressCheck.length !== 0) {
      // this.deliveryAddressCheck = this.deliveryAddress[0]
    }


  },
  computed: {
    ...mapGetters({
      user: `profile/user`,
      isAuthenticated: `auth/isAuthenticated`,
      isProfessional: `auth/isProfessional`,
      loadingUser: `profile/loadingUser`,
      updateUserLoading: `profile/updateUserLoading`,
      countries: 'auth/countriesRegistration',
      citiesList: 'auth/townsRegistration',
      states: 'auth/state'
    }),
    cityErrors() {
      let error = [];
      if (!this.$v.payload.city.$dirty) {
        return error;
      }
      if (!this.$v.payload.city.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.city) {
        this.validationErrors.city.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    stateErrors() {
      let error = [];
      if (!this.$v.payload.state.$dirty) {
        return error;
      }
      if (!this.$v.payload.state.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.state) {
        this.validationErrors.state.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    countryErrors() {
      let error = [];
      if (!this.$v.payload.country.$dirty) {
        return error;
      }
      if (!this.$v.payload.country.required) {
        error.push(this.$t('validationRequired'))
      }
      if (this.validationErrors.country) {
        this.validationErrors.country.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    allImages() {


    },
    mainAddress() {
      if (this.user) {
        for (let i in this.user.addresses.data.carrier_post) {
          if (this.user.addresses.data.carrier_post[i].is_main === 1) {
            return this.user.addresses.data.carrier_post[i]
          }
        }
      }
    },
    mainNovaPoshtaAddress() {
      if (this.user) {
        for (let i in this.user.addresses.data.new_post) {
          if (this.user.addresses.data.new_post[i].is_main === 1) {
            return this.user.addresses.data.new_post[i]
          }
        }
      }
    },
    otherNovaPoshtaAddress() {
      let arr = []
      if (this.user) {
        for (let i in this.user.addresses.data.new_post) {
          if (this.user.addresses.data.new_post[i].is_main === 0) {
            arr.push(this.user.addresses.data.new_post[i])
          }
        }
        return arr
      }
    },
    othersAddresses() {
      let arr = []
      if (this.user) {
        for (let i in this.user.addresses.data.carrier_post) {
          if (this.user.addresses.data.carrier_post[i].is_main === 0) {
            arr.push(this.user.addresses.data.carrier_post[i])
          }
        }
        return arr
      }
    }
  },
  methods: {
    ...mapActions({
      fetchUser: `profile/FETCH_USER_DATA`,
      updateInfo: `profile/UPDATE_USER_DATA`,
      createAddress: `profile/ADD_USER_ADDRESS`,
      updateAddress: `profile/EDIT_USER_ADDRESS`,
      deleteAddress: `profile/REMOVE_USER_ADDRESS`,
      linkWithSocial: `auth/LINK_WITH_SOCIAL`,
      uploadFiles: 'auth/UPLOAD_FILES',
      deleteFiles: 'auth/DELETE_FILES',
      fetchCountries: 'auth/GET_COUNTRIES',
      fetchTowns: 'auth/GET_TOWNS',
      fetchState: 'auth/GET_STATE'
    }),
    printSearchText(searchText) {
      this.searchCountryTitle = searchText

    },
    updateFiles() {
      let fd = new FormData();
      for (let j in this.files) {
        fd.append(`files[${j}]`, this.files[j]);
      }
      this.uploadFiles(fd).then(() => {
        this.$toasted.success(this.$t('fileAddedSuccessfully'));
        this.selectedFile = false
        this.fetchUser()
      })

    },

    handleFileChange(e) {
      let arr = Array.from(e.target.files)
      this.files = this.files.concat(arr)
      this.selectedFile = true
      let newArr = []
      this.files.forEach(el => {
        newArr.push({path: URL.createObjectURL(el)})
      })
      this.preview = newArr
      this.allImg = this.user.files.concat(this.preview)
    },

    editCertificate() {
      this.editProfiCertificate = true
    },
    hideEditCertificate() {
      this.editProfiCertificate = false
    },
    removeFile(file) {
      const index = this.allImg.findIndex(e => e === file)
      let previewIdx = this.files.findIndex(e => e === file)
      let element = this.allImg.find(e => e === file)
      this.allImg.splice(index, 1)
      console.log(element.id)
      if (element.id) {
        this.deleteFiles(element.id).then(() => {
          this.$toasted.success(this.$t('fileRemovedSuccessfully'));
          this.fetchUser()
        })
      } else {
        // this.files.splice(previewIdx, 1)
      }
    },
    setImage(file) {
      this.preview.push({files: URL.createObjectURL(...file.target.files).slice(5)})
      let arr = Array.from(file.target.files)
      this.files = this.files.concat(arr)

    },
    linkAccount() {
      // this.linkWithSocial(this.socialInfo)
    },

    changeEditNovaPoshta(value) {
      this.editNovaPoshta = value
    },
    updateUserInfo() {
      let obj = {}
      obj.first_name = this.payload.name
      obj.last_name = this.payload.last_name
      obj.middle_name = this.payload.middle_name
      obj.city = this.payload.city
      obj.country = this.payload.country
      obj.state = this.payload.state
      obj.work_address = this.payload.work
      obj.type = 'info'
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updateInfo(obj).then(() => {
          this.$toasted.success(this.$t('successChangeUserData'));
          this.profileDisabled = true
          this.fetchUser().then(() => {
            this.setData()
            // window.location.reload()
          })
        })
      }
    },
    updateUserContact() {
      let obj = {}
      obj.phone = this.payload.phone
      obj.email = this.payload.email
      obj.viber = this.payload.viber
      obj.telegram = this.payload.telegram
      obj.type = 'contacts'

      this.updateInfo(obj).then(() => {
        this.$toasted.success(this.$t('successChangeUserData'));
        this.contactDisabled = true
        this.fetchUser().then(() => {
          this.setData()
        })
      })

    },
    newAddress() {
      let type = ''
      if (this.editCourier) {
        type = 3
      } else if (this.editUkrPoshta) {
        type = 2
      } else {
        type = 1
      }
      this.createAddress({
        type: type,
        ...this.payload.address
      }).then(() => {
        this.payload.address.country = ''
        this.payload.address.city = ''
        this.payload.address.street = ''
        this.payload.address.house = ''
        this.payload.address.flat = ''
        this.$toasted.success(this.$t('successCreateAddress'))
        this.fetchUser()
      })
    },
    setMainAddress(id, type) {
      this.updateAddress({id: id, data: {is_main: 1, type: type}}).then(() => {
        this.$toasted.success(this.$t('successSetNewMainAddress'))
        this.fetchUser()
      })
    },
    setData() {
      this.payload.name = this.user.first_name;
      this.payload.last_name = this.user.last_name;
      this.payload.middle_name = this.user.middle_name;
      this.payload.email = this.user.email;
      this.payload.phone = this.user.phone;
      this.payload.telegram = this.user.telegram;
      this.payload.viber = this.user.viber;
      // this.payload.country = this.user.country
      // this.payload.state = this.user.state
      // this.payload.city = this.user.city


      this.payload.message = this.user.about_me;
      this.payload.work = this.user.work_address;
    },
    edit() {
      this.messageDisabled = !this.messageDisabled
      this.editPersonalData = false
    },
    editMessage() {
      let obj = {}
      obj.about_me = this.payload.message
      obj.type = 'about_me'
      this.editPersonalData = true
      this.updateInfo(obj).then(() => {
        this.fetchUser()
        this.$toasted.success(this.$t('successChangeUserData'));
        this.messageDisabled = true
      })
    },
    getPureText(str) {
      return str = str.replace(/,\s*$/, "");
    },
    removeAddress(id, type) {
      this.deleteAddress({id: id, type: type}).then(() => {
        this.$toasted.success(this.$t('successDeletedAddress'))
        this.fetchUser()
      })
    }
  }

};
